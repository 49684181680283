import { portalAction } from 'root/redux/action/portal.action';
import { systemFeatureAction } from 'root/redux/action/system-feature.action';

import notification from 'root/utils/notification';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            fields: {
                email: '',
                captchaToken: '',
            },
            errors: {},
            disabledButton: true,
            requestSuccess: false,
            requestFail: false,
            turnstileValidation: false,
        };
    },

    close() {
        controller.vm.props.dispatch(portalAction.floatingPageUpdated(''));
    },
    async initCloudflareSettings() {
        await controller.vm.props.dispatch(systemFeatureAction.loadSystemFeature());
        const turnstileEnabled = controller.enabledTurnstile();
        if (turnstileEnabled) {
            controller.vm.props.dispatch(systemFeatureAction.fetchCloudFlareTurnstileSetting());
            controller.vm.setState({ turnstileValidation: true });
        }
    },
    handleFieldChanged(e, field) {
        const { fields } = controller.vm.state;
        let errors = controller.vm.state.errors;

        fields[field] = e.target.value;
        controller.vm.setState({ fields });

        if (field === 'email' && typeof fields['email'] !== undefined) {
            let email = fields['email'];

            window.SPL_Register.validateEmailFormat(email).then((errMsg) => {
                controller.handleInputError(errMsg, 'email', errors);
            });
        }
    },

    handleInputError(errMsg, field, errors) {
        if (errMsg.length > 0) {
            errors[field] = errMsg[0];

            controller.vm.setState({ errors: errors, disabledButton: true });
        } else {
            errors[field] = '';

            controller.vm.setState({ errors: errors, disabledButton: false });
        }
    },

    reset() {
        let email = controller.vm.state.fields['email'];
        let captchaToken = controller.vm.state.fields?.captchaToken || null;

        window.SPL_Member.reqResetPasswordEmail(email, captchaToken).then((res) => {
            if (res === 'success') {
                controller.vm.setState({ requestSuccess: true, requestFail: false, disabledButton: true });
            } else {
                controller.vm.setState({ requestSuccess: false, requestFail: true, disabledButton: false }, () => {
                    setTimeout(() => {
                        controller.vm.setState({ requestFail: false });
                    }, 2000);
                });
            }
        });
    },
    handleTurnStileToken(token) {
        controller.vm.setState({ fields: { ...controller.vm.state.fields, captchaToken: token }, turnstileValidation: false });
    },
    enabledTurnstile() {
        const skipLiteApp = controller.turnstileSkipLiteApp();
        return (
            (controller.vm.props?.systemFeatureReducer?.systemFeature?.RESET_PASSWORD?.verify_security_validation || []).includes('CLOUDFLARE_TURNSTILE') &&
            skipLiteApp &&
            !!controller.vm.props?.systemFeatureReducer?.siteKey
        );
    },
    turnstileSkipLiteApp() {
        const { portal } = controller.vm.props;
        const isAllowTurnstileForLiteApp = portal?.settings?.isAllowTurnstileForLiteApp || false;
        return isAllowTurnstileForLiteApp || !window.SPL_LiteApp.isZT828LiteApp();
    },
    turnstileErrorHandling() {
        const { t } = controller.vm.props;
        notification.showNotification('error', t('register:register.messages.error.accessRestricted'));
    },
};

export default controller;
