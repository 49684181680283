export const PAYMENT_GATEWAY_SETTING = {
    amountFieldNotRequired: 'amountFieldNotRequired',
    hasSpecialImportantNotice: 'hasSpecialImportantNotice',
};

export const AMOUNT_DEPOSIT_METHOD_CODE = {
    BA: 'BA',
    RQR: 'RQR',
    QP: 'QP',
    CT: 'CT',
    QR: 'QR',
    R: 'R',
    E: 'E',
    P2P: 'P2P',
    MC: 'MC',
    BQR: 'BQR',
    EA: 'EA',
    EA1: 'eWallet1',
    B: 'B',
};

export const CMS_BANK_IMAGE_PATH = '/cms/public/payment/icons/Bank';
export const CMS_PAYMENT_GATEWAY_IMAGE_PATH = '/cms/public/payment/icons/Payment_Gateway';

export const PROVIDER_DATA = {
    SG: { provider: 'SG', key: 'sg', text: 'global:global.submenu.slots.spadeGaming', routeName: 'spadeGaming', underMaintenance: false },
    PP: { provider: 'PP', key: 'pp', text: 'global:global.submenu.slots.pp', routeName: 'pragmaticPlay', underMaintenance: false },
    MGP: { provider: 'MGP', key: 'mgp', text: 'global:global.submenu.slots.microgaming', routeName: 'microGamingPlus', underMaintenance: false },
    UL: { provider: 'UL', key: 'ul', text: 'global:global.submenu.slots.ultimate', routeName: 'ultimate', underMaintenance: false },
    // PT2: { provider: 'PT2', key: 'pt', text: 'global:global.submenu.slots.playtech', routeName: 'playTech', underMaintenance: false },
    PT3: { provider: 'PT3', key: 'pt', text: 'global:global.submenu.slots.playtech', routeName: 'playTech', underMaintenance: false },
    PG: { provider: 'PG', key: 'pg', text: 'global:global.submenu.slots.playNGo', routeName: 'playNGo', underMaintenance: false },
    SA: { provider: 'SA', key: 'sa', text: 'global:global.submenu.slots.saGaming', routeName: 'spadeGaming', underMaintenance: false },
    GP: { provider: 'GP', key: 'gp', text: 'global:global.submenu.slots.gp', routeName: 'gameplay', underMaintenance: false },
    AG2: { provider: 'AG2', key: 'ag', text: 'global:global.submenu.casino.asiaGaming', routeName: 'asia-gaming', underMaintenance: false },
    TTG: { provider: 'TTG', key: 'ttg', text: 'global:global.submenu.slots.ttg', routeName: 'toptrendgaming', underMaintenance: false },
    MEGA: { provider: 'MEGA', key: 'mega', text: 'global:global.submenu.slots.mega', routeName: 'mega888', underMaintenance: false },
    SCR: { provider: 'SCR', key: 'scr', text: 'global:global.submenu.slots.918kiss', routeName: '918kiss', underMaintenance: false },
    SCR2: { provider: 'SCR2', key: 'scr', text: 'global:global.submenu.slots.918kiss', routeName: '918kiss', underMaintenance: false },
    NS: { provider: 'NS', key: 'ns', text: 'global:global.submenu.slots.nextspin', routeName: 'nextspin', underMaintenance: false },
    HB: { provider: 'HB', key: 'hb', text: 'global:global.submenu.slots.habanero', routeName: 'habanero', underMaintenance: false },
    PGS: { provider: 'PGS', key: 'pgs', text: 'global:global.submenu.slots.pgsoft', routeName: 'pgsoft', underMaintenance: false },
    JKR: { provider: 'JKR', key: 'jkr', text: 'global:global.submenu.slots.joker', routeName: 'joker', underMaintenance: false },
    JL: { provider: 'JL', key: 'jl', text: 'global:global.submenu.slots.jili', routeName: 'jili', underMaintenance: false },
    HC: { provider: 'HC', key: 'hc', text: 'global:global.submenu.slots.hongchow', routeName: 'goldenbay', underMaintenance: false },
    NT: { provider: 'NT', key: 'nt', text: 'global:global.submenu.slots.netent', routeName: 'netent', underMaintenance: false },
    RT: { provider: 'RT', key: 'rt', text: 'global:global.submenu.slots.redtiger', routeName: 'redtiger', underMaintenance: false },
    FG: { provider: 'FG', key: 'fg', text: 'global:global.submenu.slots.funkyGames', routeName: 'funkygames', underMaintenance: false },
    MT: { provider: 'MT', key: 'mt', text: 'global:global.submenu.slots.minited', routeName: 'minited', underMaintenance: false },
    PSY8: { provider: 'PSY8', key: 'psy8', text: 'global:global.submenu.slots.psy8', routeName: 'psy8', underMaintenance: false },
    CQ9: { provider: 'CQ9', key: 'cq9', text: 'global:global.submenu.slots.cq9', routeName: 'cq9', underMaintenance: false },
    YGR: { provider: 'YGR', key: 'ygr', text: 'global:global.submenu.slots.ygr', routeName: 'ygr', underMaintenance: false },
    FC: { provider: 'FC', key: 'fc', text: 'global:global.submenu.slots.fc', routeName: 'fc', underMaintenance: false },
    BINS2: { provider: 'BINS2', key: 'bbin2', text: 'global:global.submenu.slots.bins2', routeName: 'bbin', underMaintenance: false },
    SPB: { provider: 'SPB', key: 'spb', text: 'global:global.submenu.slots.spb', routeName: 'spb', underMaintenance: false },
    JDB: { provider: 'JDB', key: 'jdb', text: 'global:global.submenu.slots.jdb', routeName: 'jdb', underMaintenance: false },
    RG: { provider: 'RG', key: 'rg', text: 'global:global.submenu.slots.rg', routeName: 'rg', underMaintenance: false },
    PS: { provider: 'PS', key: 'ps', text: 'global:global.submenu.slots.ps', routeName: 'playstar', underMaintenance: false },
    YGG: { provider: 'YGG', key: 'ygg', text: 'global:global.submenu.slots.ygg', routeName: 'ygg', underMaintenance: false },
    AP: { provider: 'AP', key: 'ap', text: 'global:global.submenu.slots.ap', routeName: 'ap', underMaintenance: false },
    SPX: { provider: 'SPX', key: 'spx', text: 'global:global.submenu.slots.spx', routeName: 'spx', underMaintenance: false },
    DRG: { provider: 'DRG', key: 'drg', text: 'global:global.submenu.slots.drg', routeName: 'drg', underMaintenance: false },
    DS: { provider: 'DS', key: 'ds', text: 'global:global.submenu.slots.ds', routeName: 'ds', underMaintenance: false },
    EXS: { provider: 'EXS', key: 'exs', text: 'providerName:ProviderName.EXS', routeName: 'exs', underMaintenance: false },
    VP: { provider: 'VP', key: 'vp', text: 'providerName:ProviderName.VP', routeName: 'vp', underMaintenance: false },
    EP: { provider: 'EP', key: 'ep', text: 'providerName:ProviderName.EP', routeName: 'ep', underMaintenance: false },
    BB: { provider: 'BB', key: 'bb', text: 'global:global.submenu.slots.bb', routeName: 'bb', underMaintenance: false },
};

export const OTP_CONTACT_TYPE = {
    PHONE: 'PHONE',
    EMAIL: 'EMAIL',
    QQ: 'QQ',
    CRYPTO: 'CRYPTO',
    SMS_VERIFICATION_BY_MEMBER: 'SMS_VERIFICATION_BY_MEMBER',
    GOOGLE_OTP: 'GOOGLE_OTP',
    WECHAT: 'WECHAT',
    CRYPTO_EMAIL_AUTH: 'CRYPTO_EMAIL_AUTH',
    ZALO: 'ZALO',
    WHATSAPP: 'WHATSAPP',
    LINE: 'LINE',
    TELEGRAM: 'TELEGRAM',
    SKYPE: 'SKYPE',
    EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
    SMS_VERIFICATION: 'SMS_VERIFICATION',
    WITHDRAW_PIN: 'WITHDRAW_PIN',
};

export const OTP_VERIFICATION_TYPE = {
    BIND_AUTHENTICATOR: 'BIND_AUTHENTICATOR',
    REGISTRATION: 'REGISTRATION',
    EXTERNAL_REGISTRATION: 'EXTERNAL_REGISTRATION',
    RESET_PASSWORD: 'RESET_PASSWORD',
    AFF_RESET_PASSWORD: 'AFF_RESET_PASSWORD',
    USER_VERIFICATION: 'USER_VERIFICATION',
    UPDATE_MEMBER_INFO: 'UPDATE_MEMBER_INFO',
    CRYPTO_ADDRESS: 'CRYPTO_ADDRESS',
    AFF_CRYPTO_ADDRESS: 'AFF_CRYPTO_ADDRESS',
    WITHDRAWAL_BANK: 'WITHDRAWAL_BANK',
    WITHDRAWAL_CRYPTO: 'WITHDRAWAL_CRYPTO',
    AFF_WITHDRAWAL_CRYPTO: 'AFF_WITHDRAWAL_CRYPTO',
    WITHDRAWAL_EWALLET: 'WITHDRAWAL_EWALLET',
    BANK_DETAIL_BANK: 'BANK_DETAIL_BANK',
    BANK_DETAIL_CRYPTO: 'BANK_DETAIL_CRYPTO',
    BANK_DETAIL_EWALLET: 'BANK_DETAIL_EWALLET',
    SIX_PIN: 'SIX_PIN',
};

export const OTP_VERIFICATION_METHOD = {
    WHATSAPP: 'WHATSAPP',
    PHONE: 'PHONE',
    EMAIL: 'EMAIL',
    GA: 'GA',
};

export const MODULE_TYPE = {
    USER_VERIFICATION: 'USER_VERIFICATION',
    UPDATE_MEMBER_INFO: 'UPDATE_MEMBER_INFO',
    CRYPTO_ADDRESS: 'CRYPTO_ADDRESS',
    AFF_CRYPTO_ADDRESS: 'AFF_CRYPTO_ADDRESS',
    WITHDRAWAL_CRYPTO: 'WITHDRAWAL_CRYPTO',
    AFF_WITHDRAWAL_CRYPTO: 'AFF_WITHDRAWAL_CRYPTO',
    WITHDRAWAL_BANK: 'WITHDRAWAL_BANK',
    WITHDRAWAL_EWALLET: 'WITHDRAWAL_EWALLET',
    BIND_AUTHENTICATOR: 'BIND_AUTHENTICATOR',
    BANK_DETAIL_BANK: 'BANK_DETAIL_BANK',
    BANK_DETAIL_EWALLET: 'BANK_DETAIL_EWALLET',
    SIX_PIN: 'SIX_PIN',
};

export const POPUP_TYPE = {
    VERIFY_EMAIL: 'VERIFY_EMAIL',
    VERIFY_PHONE: 'VERIFY_PHONE',
    GA: 'GA',
    EDIT_VERIFY_AUTH: 'EDIT_VERIFY_AUTH',
    PIN_ENTRY: 'PIN_ENTRY',
    SIX_PIN_SETUP: 'SIX_PIN_SETUP',
};

export const EMAIL_VERIFICATION_FIELDS = {
    EMAIL: 'email',
    OTP: 'otp',
};
export const EMAIL_VERIFICATION_STEP = {
    INPUT_EMAIL: 'INPUT_EMAIL',
    ENTER_OTP: 'ENTER_OTP',
    SUCCESS: 'SUCCESS',
};

export const PHONE_VERIFICATION_FIELDS = {
    PHONE: 'phone',
    OTP: 'otp',
};
export const PHONE_VERIFICATION_STEP = {
    INPUT_PHONE: 'INPUT_PHONE',
    ENTER_OTP: 'ENTER_OTP',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
};

export const GA_FIELD = {
    KEY: 'key',
    CODE: 'gaCode',
    OTP: 'otp',
};

export const CRYPTO_ADDRESS_FIELD = {
    COIN: 'coin',
    NETWORK: 'network',
    ADDRESS: 'address',
    OTP: 'otp',
    GA: 'gaCode',
};

export const UPDATE_MEMBER_INFO_FIELD = {
    EMAIL: 'email',
    PHONE: 'phone',
    OTP: 'otp',
    GA: 'gaCode',
};

export const SIX_PIN_SETUP_FIELD = {
    NEW_PIN: 'newPin',
    CONFIRM_PIN: 'confirmPin',
    OTP: 'otp',
};

export const CONTACT_VERIFICATION_METHODS = {
    PHONE: ['PHONE', 'WHATSAPP'],
    EMAIL: ['EMAIL'],
};
